import React from 'react';

function Header() {
  return (
    <header className="header row">
      <p>
        <code>Radu Farcas</code>
      </p>
    </header>
  );
};

export default Header;
